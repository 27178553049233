<template>

  <el-dialog title="导出"
             :visible.sync="dialogVisible"
             :before-close="handleClose"
             :center="true">
    <div style="display: flex;flex-direction: column;">
      <el-checkbox disabled v-model="has_question">包含题目</el-checkbox>
      <el-checkbox v-model="has_explain">包含题目答案、解析</el-checkbox>
      <!-- <el-checkbox v-model="has_source">包含题目来源</el-checkbox> -->
    </div>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="confirm">确 定</el-button>
    </span>
  </el-dialog>

</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      has_question: true,
      has_explain: false,
      has_source: false,
    };
  },
  methods: {
    confirm () {
      this.$emit('confirm', +this.has_explain, +this.has_source); this.dialogVisible = false;
    },
    handleClose () {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
::v-deep .el-dialog__body {
  display: flex;
  justify-content: center;
}
</style>