<template>
  <div class="home_warp">
    <!-- 路由含fromM则是在初中的iframe中，避免多重嵌套-->
    <iframe id="myIframe"
            v-if="!$route.query.fromM"
            :src="middleUrl+'/login?fromH=1'"
            width="500px"
            style="display:none" />
    <div class="login_warp">
      <div class="title">
        天天进步智能备考系统
        <!-- 高考智能学伴 -->
      </div>
      <div class="level">
        <span style="color:#999;cursor:pointer"
              class="level_normal"
              @click="toMiddle">初中</span>
        <span style="color:#2196f3"
              class="level_active">高中</span>
      </div>
      <el-form :model="form"
               class="form">
        <el-row>
          <el-input class="input_class"
                    v-model="form.phone"
                    placeholder="请输入账号">
            <img slot="prefix"
                 class="icons"
                 src="../../assets/login/账号.png"
                 alt="">
          </el-input>
        </el-row>
        <el-row>
          <el-input class="input_class"
                    v-model="form.code"
                    placeholder="请输入短信验证码">
            <img slot="prefix"
                 class="icons"
                 src="../../assets/login/ShieldCheck.png"
                 alt="">
            <template #suffix>
              <van-button size="small"
                          class="btns_sms"
                          style=""
                          @click="sendSms"
                          native-type="button"
                          v-show="num==0"> 发送验证码</van-button>
              <span v-show="num>0"
                    style="color:#ccc;line-height:56rem">
                {{num}}s后重新获取
              </span>
            </template>

          </el-input>

        </el-row>
        <!-- <el-row>
          <el-input class="input_class"
                    type="password"
                    v-model="form.password"
                    placeholder="请输入密码">
            <img slot="prefix"
                 class="icons"
                 src="../../assets/login/密码.png"
                 alt="">
          </el-input>
        </el-row>-->
        <el-row>
          <el-select class="input_class"
                     v-model="form.user_class_id"
                     style="width: 100%;"
                     placeholder="请选择版本">
            <el-option v-for="item in versionList"
                       :key="item.user_class_id"
                       :label="item.user_class_name"
                       :value="item.user_class_id">
            </el-option>
            <template slot="prefix">
              <img class="icons"
                   src="../../assets/login/版本.png"
                   alt="">
            </template>
          </el-select>
        </el-row>
        <!-- <div class="check_warp">
          <div>
            <el-checkbox v-model="opertionForm.rememberPassword"
                         @change="rememberPassword">记住密码</el-checkbox>
          </div>
          <div>
            <el-checkbox v-model="opertionForm.autoLogin"
                         @change="autoLogin">自动登录</el-checkbox>
          </div>
        </div> -->
        <div class="check_warp"
             style="margin-top:20rem">
          <div>
            <el-checkbox v-model="opertionForm.consent">同意</el-checkbox>
            <span class="protocol"
                  @click="showProtocol">《用户协议》
            </span>
          </div>
        </div>
      </el-form>
      <div class="btn_warp">
        <div class="btn"
             @click="toHome">
          登 录
        </div>
        <div class="btn_group">
          <div class="text"
               @click="toRegister">
            免费注册
          </div>
          <div class="text">|
          </div>

          <div class="text"
               @click="toLoginP">
            密码登录
          </div>
        </div>
        <img  style="margin-top: 24rem;"
                   src="../../assets/login/Rectangle.png"
                   alt="">
        <div class="text"
        style="margin-top: 24rem;"
             @click="toActivate">
          学习卡激活
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               top="1vh"
               width="1050rem"
               :before-close="handleClose">
      <protocol />
      <div class="btn_warp">
        <el-button @click="handleClose"
                   type="primary">
          关闭
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="qrVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               top="1vh"
               width="1050rem"
               :before-close="qrClose">
      <div style="    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;">
        <div style="font-size: 32rem">你的免费次数已用完，请联系客服</div>
        <img class=""
             src="../../assets/login/二维码.png"
             alt="">
      </div>
      <div class="btn_warp">
        <el-button @click="qrClose"
                   type="primary">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import middleUrl from '@/utils/middleUrl.js'
import protocol from '@/views/personalCenter/components/protocol'
import CryptoJS from 'crypto-js';
import { loginApi } from '@/api/login.js'
import { Toast } from 'vant';
import { getverify, loginVerify, toActivate, invitecodeVerify, getGrade, getPalceList, verifyUser, getSelectSubject } from '@/api/activate.js'

export default {
  data () {
    return {
      middleUrl,
      num: 0,

      form: {
        // phone: '15552298893',
        // password: '298893',
        phone: '',
        sms_record_id: null,
        code: '',
        password: '',
        app_id: '1',
        app_key: 'daydayup',
        app_version: '1.7.9',
        pad_type: 'web'
      },
      versionList: [
      ],
      checked: false,
      opertionForm: {
        rememberPassword: false,
        autoLogin: '',
        consent: true
      },
      dialogVisible: false,
      isAuto: true,
      secretkey: 'acdb',
      qrVisible: false

    }
  },
  components: { protocol },
  mounted () {
    this.getMData()

    if (!this.$route.query.fromM) {
      this.$http({
        url: '/api/v1/app/class_list',
        method: 'get',
      }).then(res => {
        this.versionList = res.data
      })
    }

  },
  methods: {
    qrClose () {
      this.qrVisible = false
    },
    async sendSms () {
      if (!this.form.phone) {
        this.$message.error('请输入手机号');
        return
      }
      try {
        console.log('sssssssssss', this.form.mobile)
        if (this.form.phone) {
          const { data } = await loginVerify({
            mobile: this.form.phone,

          })
          this.form.sms_record_id = data.sms_record_id
          Toast({
            message: '发送成功',
            position: 'top',
            className: 'toast_class'
          });
          this.num = 59
          this.intervalId = setInterval(() => {
            this.num -= 1;
            if (this.num == 0) {
              clearInterval(this.intervalId)
            }
          }, 1000);
        } else if (this.form.grade_id == 2) {
          // 初中
          await getverifyM({
            mobile: this.form.phone,
            type: register2
          })
          Toast({
            message: '发送成功',
            position: 'top',
            className: 'toast_class'
          });
          this.num = 59
          this.intervalId = setInterval(() => {
            this.num -= 1;
            if (this.num == 0) {
              clearInterval(this.intervalId)
            }
          }, 1000);
        }

      } catch (err) {
        // Toast({
        //   message: err.msg,
        //   position: 'top',
        // });
      }
    },
    getMData () {
      var aData = localStorage.getItem("aPageData");
      if (aData) {
        this.setMData(aData);     // 当能获取到数据时就说明是从高中页面跳转过来的
        localStorage.removeItem("aPageData");
      } else if (this.$route.query.fromM) {
        // 在初中iframe中监听
        window.addEventListener("message", receiveMessage, false);
      } else {
        // console.log('chuzhong')
        this.initData()
      }
      function receiveMessage (event) {
        if (event.origin !== middleUrl && event.origin + '/#' !== middleUrl) return;
        if (event.data) {
          localStorage.setItem("aPageData", event.data);
        }
      }
    },
    toLoginP () {
    this.$router.go(-1)
    },
    toActivate () {
      // this.$router.push('/activate')
      let routeUrl = this.$router.resolve({
        path: "/activate"
      });
      window.open(routeUrl.href, '_blank');
    },
    toRegister () {
      this.$router.push('/register')
    },

    setMData (data) {
      let postData = JSON.parse(data)
      this.form.phone = postData.form.account
      this.form.password = postData.form.password
      this.opertionForm.rememberPassword = postData.opertionForm.rememberPassword
      this.opertionForm.autoLogin = postData.opertionForm.autoLogin
    },

    initData () {
      if (window.localStorage.getItem("userInfo") && this.isAuto) {
        this.$router.push('/index')
        return
      }
      let form = window.localStorage.getItem('loginForm')
      let opertionForm = window.localStorage.getItem('opertionForm')

      if (JSON.parse(opertionForm)) {
        this.opertionForm = JSON.parse(opertionForm)
      }
      if (this.opertionForm.rememberPassword) {
        if (form) {
          form = JSON.parse(form)
          if (form.password.length > 10) {
            form.password = CryptoJS.AES.decrypt(form.password, this.secretkey).toString(CryptoJS.enc.Utf8)
          }
          this.$set(this, 'form', form)

        }
      }
      if (this.opertionForm.autoLogin && !this.$route.query.not_auto_login) {
        this.toHome()
      } else {
        window.localStorage.removeItem('opertionForm')
      }
    },
    async toHome () {
      if (this.form.phone == '') {
        this.$message.warning('请输入账号');
        return
      }
      // if (this.form.password == '') {
      //   this.$message.warning('请输入密码');
      //   return
      // }
      if (!this.opertionForm.consent) {
        this.$message.warning('请同意用户协议');
        return
      }
      const { data } = await loginApi(this.form)

      if (data.code == 501) {
        window.localStorage.setItem('unLogingMeassge', JSON.stringify(this.form))
        this.$router.push('/register1')
        return
      }
      this.$http({
        url: '/api/v1/login',
        method: 'POST',
        data: this.form
      }).then(res => {
        window.localStorage.setItem('userInfo', JSON.stringify(res.data))
        if (res.data.edu_school_id == 1) {
          if (this.opertionForm.rememberPassword || this.opertionForm.autoLogin) {
            let form = JSON.parse(JSON.stringify(this.form))
            // 简单加密
            form.password = CryptoJS.AES.encrypt(this.form.password, this.secretkey).toString()
            window.localStorage.setItem('loginForm', JSON.stringify(form))
          }
          window.localStorage.setItem('opertionForm', JSON.stringify(this.opertionForm))
        }
        this.$message.success('登录成功')
        window.localStorage.removeItem('unLogingMeassge')
        let subjectListData = []
        this.$http({
          url: '/api/v1/cp/subject_list',
          method: 'get'
        }).then(res => {
          let totalScore = 0; // 定义变量用于累加分数
          subjectListData = res.data.list.filter(item => item.subject_id !== 13).map(item => {
            totalScore += item.score;

            // if ((item.subject_id === 7 || item.subject_id === 12 || item.subject_id === 11) && item.score == 0) {
            //   item.score = 130; // 设置为130
            // } else if (item.score == 0) {
            //   item.score = 90; // 其他设为90
            // }

            return {
              subject_id: item.subject_id,
              score: item.score,
            }
          });
          window.localStorage.setItem('subjectListData', JSON.stringify(subjectListData))
          window.localStorage.setItem('totalScore', totalScore)
          if (totalScore > 0) {
            window.localStorage.setItem('score_dialog', false)
          }

          this.$router.push('/home')
        })

      }).catch(e => {
        if (e.data.msg == "test user expire") {
          this.qrVisible = true
        }


      })
    },
    autoLogin () {
      if (this.opertionForm.autoLogin) {
        this.opertionForm.rememberPassword = true
      }
    },
    rememberPassword () {
      if (this.opertionForm.autoLogin && !this.opertionForm.rememberPassword) {
        this.opertionForm.autoLogin = false
      }
    },
    toMiddle () {
      // 利用postMessage，iframe,localStroage传递高中输入的数据到初中页面
      let myIframe = document.getElementById("myIframe");
      if (myIframe) {
        let data = { form: this.form, opertionForm: this.opertionForm }
        myIframe.contentWindow.postMessage(JSON.stringify(data), middleUrl + '/login?fromH=1')
        window.open(middleUrl, "_self")

      } else {
        // window.location.href = middleUrl
        return
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    showProtocol () {
      this.dialogVisible = true
    }

  },
  beforeRouteEnter (to, from, next) {
    // 若是从’/index‘，跳转到’/login‘，则不默认直接跳转’/index'
    if (from.path == '/index') {
      next(vm => {
        vm.isAuto = false
      })
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.home_warp {
  width: 100vw;
  // height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/login/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .login_warp {
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 256rem;
      height: 176rem;
      top: -152rem;
      left: 50%;
      margin-left: -125rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    flex: 1;
    // margin-top: 25vh;
    // margin-left: 32%;
    // margin-right: 32%;
    // margin-top: 71rem;
    position: relative;
    margin-top: 129rem;
    margin-left: 560rem;
    margin-right: 560rem;
    height: fit-content;
    // padding-bottom: 84rem;
    padding-bottom: 60rem;
    text-align: center;
    // background-image: url("../../assets/login/白板.png");
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    background: #ffffff;
    box-shadow: 0px 2rem 14rem 0px rgba(141, 184, 227, 0.32);
    border-radius: 20rem;
    border: 20rem solid #dcedfc;
    text-align: center;
    .title {
      font-size: 40rem;
      font-weight: 800;
      margin-top: 52rem;
      color: #2196f3;
      text-align: center;
      line-height: 1.6;
    }
  }
}

::v-deep .el-input--medium .el-input__inner {
  height: 40rem;
  line-height: 40rem;
  font-size: 16rem;
}
::v-deep .van-button--small {
  height: 56rem;
  padding: 0 8rem;
  font-size: 16rem;
}
.icons {
  width: 20rem;
  height: 20rem;
}
.form {
  width: 480rem;
  margin: 0 128rem;
}
.btns_sms {
  background: transparent;
  border: 0;
  color: #2196f3;
}
.check_warp {
  display: flex;
  margin-top: 24rem;
  div {
    flex: 1;
    color: #666666;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
}
.btn_warp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 28rem;
  flex-direction: column;
  align-items: center;
  .btn_group {
    // width: 196rem;
    margin-top: 16rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    .text:hover {
      color: #2196f3;
      cursor: pointer;
    }
  }
  .btn {
    width: 360rem;
    height: 48rem;
    line-height: 48rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    font-size: 18rem;
    color: white;
    border-radius: 44rem;
    background: #fb9930;
    font-weight: bold;
    // letter-spacing: 10rem;
    cursor: pointer;
  }
  .btn_text {
    margin-top: 20rem;
    line-height: 24rem;
    cursor: pointer;
    font-size: 18rem;
    color: #fb9930;
    text-decoration: underline;
    &:hover {
      color: #2196f3;
    }
  }
  .text {
    color: var(--light-666666, var(--neutral-color-666666, #666));
    text-align: center;
    font-family: "PingFang SC";
    font-size: 20rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem; /* 150% */
    // width: 80rem;
  }
}
::v-deep .el-input__prefix {
  left: 12rem;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 41rem !important;
  font-size: 18rem !important;
}
::v-deep .el-input--medium .el-input__inner {
  height: 56rem !important;
  line-height: 56rem !important;
}
.el-input__prefix {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    padding-left: 1rem;
    width: 24rem;
    height: 24rem;
  }
}
::v-deep .el-input {
  margin-top: 16rem;
  font-size: 18rem;
}

::v-deep .el-input__inner::placeholder {
  font-size: 18rem;
}
::v-deep .el-checkbox__label {
  font-size: 18rem;
  padding-left: 8px;
}
::v-deep .el-checkbox__inner {
  width: 20rem;
  height: 20rem;
  border-radius: 4rem;
}
::v-deep .el-checkbox__input {
  line-height: 1.5;
}

::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 2rem solid #fff;
  border-left: 0;
  border-top: 0;
  width: 5rem;
  height: 11rem;
  left: 6rem;
  position: absolute;
  top: 1rem;
  transform: rotate(38deg) scaleY(0);
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}

::v-deep .el-input__prefix {
  display: flex;
  align-items: center;
}
.level {
  margin-top: 20rem;
  font-size: 18rem;
  width: 360rem;
  margin: 20rem auto 0;
  display: flex;
  justify-content: space-around;
  line-height: 1.5;
  span {
    position: relative;
    padding-left: 25rem;
  }
  span:before {
    width: 18rem;
    height: 18rem;
    box-sizing: border-box;
    content: "";
    // border: 3rem solid #2196f3;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    margin-top: -9rem;
    left: 0;
  }
  span.level_active:before {
    border: 3rem solid #2196f3;
  }
  span.level_normal:before {
    border: 1rem solid #ccc;
  }
}
.protocol {
  color: #3f9eff;
  font-size: 18rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
::v-deep .el-dialog__body {
  padding: 0px 20px 30px;
}
::v-deep .el-dialog__header {
  padding: 0px 20px 10px;
}
::v-deep .el-dialog__headerbtn {
  padding: 2px 3px 1px;
  border-radius: 50%;
  border: 1px solid #bbb;
}
</style>
