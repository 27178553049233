import axios from 'axios'
import basicUrl from './basicUrl'
import router from '@/router'
import { Message, Loading } from 'element-ui'
import getMd5Form from '@/utils/getMd5Form.js'
import { applyToken, syncTimePost } from '@/api/useTime'


export const instance = axios.create({
  baseURL: basicUrl,
  timeout: 3600000,
});

export const http_file = axios.create({
  baseURL: basicUrl,
  timeout: 3600000,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  transformRequest: [(data) => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    return formData
  }]
})

// 设置放行路由，放行错误控制
var role = [
  '/api/v1/student/export_stat',
  '/api/v1/combination/stats_export',
  '/api/v1/my/print_pdf'
]

function getTime () {
  if (window.localStorage.getItem('userInfo')) {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    var time = Date.parse(new Date()).toString(); //精确到毫秒
    time = time.substr(0, 10); //精确到秒
    if (userInfo) {
      if (userInfo.exp - time <= 180) {
        window.sessionStorage.removeItem('userInfo')
        setTimeout(() => {
          Message.error('登录已过期，请重新登录')
          return true
        }, 100);
        window.localStorage.clear()
        router.push('/login?not_auto_login=1')
      }
    }
  }
}
//判断注册用户是否超过一天
export function getOneDayTime () {
  if (window.localStorage.getItem('userInfo')) {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    var time = Date.now(); // 获取当前时间的毫秒表示形式
    if (userInfo.user_type_id == 14) {
      var diffInDays = Math.floor((time - new Date(userInfo.vip_end_time).getTime()) / (24 * 60 * 60 * 1000)); // 计算两个日期之间的天数差
      if (diffInDays > 0) {
        Message.info('会员已过期')
        userInfo.vip_end_time = ''
        window.localStorage.setItem("userInfo", JSON.stringify(userInfo))

      }
    }
  }
}

async function getToken (data) {
  const res = await applyToken(data);
  return res
}

export var requestSign = {
  number: 0,
  setNumber: function (number) {
    requestSign.number = number
  }
}

export var loadings
function openLoading (textdata) {
  // 开启全局loading
  if (!requestSign.number) {
    loadings = Loading.service({
      fullscreen: true,
      target: document.querySelector('#app'),
      text: textdata || '正在加载中!',
      spinner: 'el-icon-loading'
    });
  }
  requestSign.number += 1
}

function closeLoading () {
  // return
  setTimeout(() => {
    requestSign.number -= 1
    if (requestSign.number < 0) {
      requestSign.number = 0
    }
    if (requestSign.number == 0) {
      loadings.close()
    }
  }, 500);
}
function addInterceptors (face) {
  face.interceptors.request.use(config => {
    if (config.method == 'get') {
      if (!config.params) {
        config.params = {}
      }
      if (config.url == '/api/rpc/v1/token') {
        config.params.sign = getMd5Form(config.params, config.url)
      } else {
        config.params.sign = getMd5Form(config.params)
      }
    } else {
      if (!config.data) {
        config.data = {}
      }
      if (config.url == '/api/rpc/v1/user/usage') {
        config.data.sign = getMd5Form(config.data, config.url)

      } else {
        config.data.sign = getMd5Form(config.data)

      }

    }
    let urlToken = router.history.current.query.token
    let userInfo = null
    if (urlToken) {
      userInfo = {
        token: urlToken
      }
    }
    if (!userInfo) {
      userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    }
    if (config.url == '/api/v1/login') {
      let params = {
        appid: 'slzn_admin_tester',
        secret: 'xMG$ARmPlfNqYk)h!sPZ',
        token_type: 'oauth'
      }
      getToken(params).then(res => {
        window.localStorage.setItem('appToken', JSON.stringify(res.data));
      }).catch(err => {
        console.log(err);
      })

    }
    if (userInfo) {
      config.headers['Authorization'] = `Bearer ${userInfo.token || ''}`
    }
    if (config.url == '/api/rpc/v1/user/usage' && config.method == 'post') {
      let appToken = null
      appToken = JSON.parse(window.localStorage.getItem('appToken'))
      config.headers['Authorization'] = `Bearer ${appToken.token || ''}`
    }

    if (config.url == '/api/rpc/v1/user/usage' && config.method == 'options') {
      let appToken = null
      appToken = JSON.parse(window.localStorage.getItem('appToken'))
      config.headers['Authorization'] = `Bearer ${appToken.token || ''}`
    }

    let textdata = ''
    if (config.url == '/api/v1/user/teacher_report') {
      textdata = '正在生成中，请耐心等待...'
    } else if (config.url == '/api/v1/user/check_explain_report') {
      textdata = '正在生成中，由于教辅数据较大，需1-2分钟，请耐心等待...'
    }
    openLoading(textdata)

    return config
  })
  face.interceptors.response.use(async config => {
    closeLoading()

    if (role.findIndex(item => item == config.config.url) != -1) {
      return config
    }

    if (config.data.status === undefined && config.data.msg === undefined) {
      return config.data
    }

    config = config.data
    return await new Promise((resolve, reject) => {
      
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.msg == 'error') {
          Message.error(config.data.msg)
        } else {
          if ((config.status == 410000 || config.status == 410005) && JSON.parse(window.localStorage.getItem('userInfo'))) {
            //未登录状态下的提示语
            Message.error(config.msg)
          } else if (config.status != 410000 && config.status != 410005) {
            //登录状态下的提示语
            Message.error(config.msg)
          }

        }
        if (config.status == 410000 || config.status == 410005) {
          window.localStorage.clear()
          // window.localStorage.setItem('unLogingMeassge', true)//设置标记 避免重复提示
          router.push('/login?not_auto_login=1')
        }
        reject(config)
      }
    })
  })
}

addInterceptors(instance)
addInterceptors(http_file)
