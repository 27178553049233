<template>
  <div>
    <div class="top">
      <!-- 
        传参 : text 显示的tab名称
               showBack 是否显示返回按钮
               isTabs 是否为tabs 为true的话 显示可选择tab项
       -->
      <img src="@/assets/leftcontroller/返回.png"
           v-if="showBack"
           @click="back"
           alt="">
      <div class="tab"
           v-if="!isTabs">
        {{text}}
      </div>

      <div class="tabs"
           v-else>
        <div :class="{'item':true,'activeItem':index==activeIndex}"
             @click="checkItem(index,item)"
             v-for="(item,index) in tabList"
             :key="index">
          {{item.label}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: 0
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    showBack: {
      type: Boolean,
      default: true
    },
    isTabs: {
      type: Boolean,
      default: false
    },
    tabList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    back () {
      window.sessionStorage.removeItem('topic_index')
      this.$router.go(-1)
    },
    checkItem (index) {
      this.activeIndex = index
      this.$parent.checkStatus(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  display: flex;
  // width: 100vw;
  justify-content: center;
  align-items: center;
  height: 100rem;
  img {
    position: absolute;
    width: 50rem;
    height: 50rem;
    top: 15rem;
    left: 25rem;
    cursor: pointer;
  }
  .tab {
    width: 260rem;
    height: 54rem;
    border-radius: 30rem;
    background: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24rem;
    font-weight: 800;
    color: #ffffff;
  }
  .tabs {
    width: calc(100% - 42.86%);
    display: flex;
    justify-content: center;
    border-radius: 30rem;
    background: #ffffff;
    height: 54rem;
    font-size: 24rem;
    font-weight: 800;
    overflow: hidden;
    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2196f3;
      cursor: pointer;
    }
  }
  .activeItem {
    background: #2196f3;
    color: #ffffff !important;
  }
}
</style>