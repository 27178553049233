import { instance } from '@/utils/http.js'
import Vue from 'vue'

// 上传文件
export const uploadFile = function (data) {
  return instance({
    url: '/api/v1/my/upload_answer_file',
    method: 'post',
    Headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 上传文件方法 二次封装  选择文件上传 一气呵成
// export const uploadImageAuto = async function (limit = 20, fileType = "image/*") {
export const uploadImageAuto = async function (limit = 20, fileType = "image/*",not_judged) {
  const res = await Vue.prototype.$get_file({ limit, fileType })
  let formData = new FormData()
  
  if(not_judged==0){
    formData.append('not_judged', 0)
  }
  formData.append('filename', 'image')
  formData.append('image', res)
  formData.append('sign', Vue.prototype.$getMd5Form({ filename: 'image' }))
  const data = await uploadFile(formData)
  return data
}

