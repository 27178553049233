<template>
  <div class="home_warp">
    <!-- 路由含fromM则是在初中的iframe中，避免多重嵌套-->
    <iframe id="myIframe"
            v-if="!$route.query.fromM"
            :src="middleUrl+'/login?fromH=1'"
            width="500px"
            style="display:none" />

    <div class="login_warp">
      <div class="fixed_wrap">
        <img src="@/assets/leftcontroller/返回.png"
             @click="back"
             class="fixed_back_btn"
             alt="">
      </div>
      <div class="title">
        天天进步
        <!-- 高考智能学伴 -->
      </div>
      <!-- <div class="level">
        <span style="color:#999;cursor:pointer"
              class="level_normal"
              @click="toMiddle">初中</span>
        <span style="color:#2196f3"
              class="level_active">高中</span>
      </div> -->
      <div class="base_title">完善资料</div>
      <el-form :model="form"
               class="form">
        <!-- <div v-show="!isShowLogin">
          <el-row>
            <el-input class="input_class"
                      v-model="form.phone"
                      placeholder="请输入账号">

            </el-input>
          </el-row>
          <el-row>
            <el-input class="input_class"
                      v-model="form.code"
                      placeholder="请输入短信验证码">
              <template #suffix>
                <van-button size="small"
                            class="btns_sms"
                            style=""
                            @click="sendSms"
                            native-type="button"
                            v-show="num==0"> 发送验证码</van-button>
                <span v-show="num>0"
                      style="color:#ccc;line-height:56rem">
                  {{num}}s后重新获取
                </span>
              </template>

            </el-input>

          </el-row>
          <el-row>
            <el-input class="input_class"
                      type="password"
                      v-model="form.password"
                      @blur="checkPassword"
                      placeholder="请输入密码">

            </el-input>
          </el-row>
          <el-row>
            <el-input class="input_class"
                      type="password"
                      v-model="form.passwordCheck"
                      @blur="checkPassword2"
                      placeholder="请再次输入密码">

            </el-input>
          </el-row>
          <el-row>
            <el-input class="input_class"
                      v-model="form.invite_code"
                      placeholder="请输入邀请码（选填）">

            </el-input>
          </el-row>
        </div> -->

        <div>
          <el-row>
            <el-input class="input_class"
                      v-model="form.truename"
                      clearable
                      placeholder="请输入学生姓名">

            </el-input>

          </el-row>
          <el-row>
            <el-select v-model="form.edu_grade_id"
                       class="input_class"
                       clearable
                       placeholder="请选择年级">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-row>

          <el-row>
            <el-select v-model="form.paper_district_id"
                       class="input_class"
                       @change="gradeChange"
                       clearable
                       placeholder="请选择地区">
              <el-option v-for="item in placeList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-row>
          <el-row>
            <el-select v-model="form.subject_ids"
                       class="input_class"
                       multiple
                       clearable
                       :multiple-limit="1"
                       placeholder="请选择科目">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id"
                         :disabled="isSubjectDisabled(subject_id)&&item.subject_id !=7&&item.subject_id !=11&&item.subject_id !=12&&item.subject_id !=13">
              </el-option>

            </el-select>
          </el-row>
        </div>
        <!-- <div class="check_warp">
          <div>
            <el-checkbox v-model="opertionForm.rememberPassword"
                         @change="rememberPassword">记住密码</el-checkbox>
          </div>
          <div>
            <el-checkbox v-model="opertionForm.autoLogin"
                         @change="autoLogin">自动登录</el-checkbox>
          </div>
        </div> -->
        <div class="check_warp"
             style="margin-top:20rem">
          <div>
            <el-checkbox v-model="opertionForm.consent">同意</el-checkbox>
            <span class="protocol"
                  @click="showProtocol">《用户协议》
            </span>
          </div>
        </div>
      </el-form>
      <div class="btn_warp">
        <div class="btn"
             @click="toHome(isShowLogin)">
          确认
        </div>

      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               top="1vh"
               width="1050rem"
               :before-close="handleClose">
      <protocol />
      <div class="btn_warp">
        <el-button @click="handleClose"
                   type="primary">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import middleUrl from '@/utils/middleUrl.js'
import protocol from '@/views/personalCenter/components/protocol'
import CryptoJS from 'crypto-js';
import { Toast } from 'vant';
import { getverify, toActivate, getGrade, getPalceList, verifyUser, getSelectSubject } from '@/api/activate.js'
import { getverifyM, toActivateM, getGradeM } from '@/api/activateMiddle.js'
export default {
  data () {
    return {
      middleUrl,
      form: {
        // phone: '15552298893',
        // password: '298893',
        phone: '',
        password: '',
        // code: '',
        // invite_code: '',
        // passwordCheck: '',
        edu_grade_id: '',
        paper_district_id: '',
        truename: '',
        // type: 'register2',
        app_id: '1',
        app_key: 'daydayup',
        app_version: '1.7.9',
        pad_type: 'web', subject_ids: []
      },
      checked: false,
      opertionForm: {
        rememberPassword: false,
        autoLogin: '',
        consent: true
      },
      dialogVisible: false,
      isAuto: true,
      secretkey: 'acdb',
      num: 0,
      isShowLogin: false,
      gradeList: [],
      placeList: [],
      subjectList: [],
      isPass: false,
      isCheck: false,
      disabledSubjects: [4, 9, 10, 6, 5, 3]

    }
  },
  watch: {
    'form.subject_ids': {
      handler (newVal, oldVal) {
        const abledSubjects = [4, 9, 10, 6, 5, 3]; // 6选3

        const disabledCount = abledSubjects.filter(subject_id => newVal.includes(subject_id)).length;
        const flag = this.subjectList.length == 9 ? 6 : 7
        if (disabledCount >= 3 && flag == this.form.subject_ids.length) {
          this.$message.info("除了语数（文理）英 ，剩下6科只能选三科！");
        }
      },
      deep: true // 如果 form.subject_ids 是一个数组或对象，需要设置为 true 才能深度监视其变化
    }
  },
  components: { protocol },
  mounted () {
    if (this.$route.query.invite_code) {
      this.form.invite_code = this.$route.query.invite_code
      window.localStorage.setItem('invite_code', this.$route.query.invite_code)
    } else {
      if (window.localStorage.getItem('invite_code')) {
        this.form.invite_code = window.localStorage.getItem('invite_code')
      }
    }
    this.getGradeList()
    this.getPlace()

  },
  methods: {
    isSubjectDisabled (subjectId) {
      const abledSubjects = [4, 9, 10, 6, 5, 3]; // 6选3
      const disabledCount = abledSubjects.filter(subjectId => this.form.subject_ids.includes(subjectId)).length;

      return disabledCount >= 3 && !abledSubjects.includes(subjectId);
    },
    gradeChange () {
      this.form.subject_ids = []
      this.getSelectSubjectList()
    },
    back () {
      // if (this.isShowLogin) {
      //   this.isShowLogin = false
      // } else {
      this.$router.go(-1)

      // }
    },
    checkPassword () {
      if (this.form.password.length < 6) {
        this.isCheck = false
        this.$message.error('密码长度不能少于6位')
      } else {
        this.isCheck = true
      }

    },
    checkPassword2 () {
      if (this.form.passwordCheck.length < 6) {
        this.$message.error('密码长度不能少于6位')
        return
      }
      if (this.form.password != this.form.passwordCheck) {
        this.isPass = false
        this.$message.error('两次密码输入不一致')
      } else {
        this.isPass = true
      }
    },
    async getPlace () {
      const { data } = await getPalceList()
      this.placeList = data.list
    },
    async getSelectSubjectList () {
      const { data } = await getSelectSubject({
        edu_grade_id: this.form.edu_grade_id,
        paper_district_id: this.form.paper_district_id
      })
      this.subjectList = data.subject_list
    },
    async getGradeList () {
      // if (this.level == 1) {
      const { data } = await getGrade()
      this.gradeList = data.list
      // } else if (this.level == 2) {
      //   const { data } = await getGradeM()
      //   this.gradeList = data.list
      // }
    },

    getMData () {
      var aData = localStorage.getItem("aPageData");
      if (aData) {
        this.setMData(aData);     // 当能获取到数据时就说明是从高中页面跳转过来的
        localStorage.removeItem("aPageData");
      } else if (this.$route.query.fromM) {
        // 在初中iframe中监听
        window.addEventListener("message", receiveMessage, false);
      } else {
        // console.log('chuzhong')
        this.initData()
      }
      function receiveMessage (event) {
        if (event.origin !== middleUrl && event.origin + '/#' !== middleUrl) return;
        if (event.data) {
          localStorage.setItem("aPageData", event.data);
        }
      }
    },
    toActivate () {
      // this.$router.push('/activate')
      let routeUrl = this.$router.resolve({
        path: "/activate"
      });
      window.open(routeUrl.href, '_blank');
    },

    setMData (data) {
      let postData = JSON.parse(data)
      this.form.phone = postData.form.account
      this.form.password = postData.form.password
      this.opertionForm.rememberPassword = postData.opertionForm.rememberPassword
      this.opertionForm.autoLogin = postData.opertionForm.autoLogin
    },

    initData () {
      if (window.localStorage.getItem("userInfo") && this.isAuto) {
        this.$router.push('/index')
        return
      }
      let form = window.localStorage.getItem('loginForm')
      let opertionForm = window.localStorage.getItem('opertionForm')

      if (JSON.parse(opertionForm)) {
        this.opertionForm = JSON.parse(opertionForm)
      }
      if (this.opertionForm.rememberPassword) {
        if (form) {
          form = JSON.parse(form)
          if (form.password.length > 10) {
            form.password = CryptoJS.AES.decrypt(form.password, this.secretkey).toString(CryptoJS.enc.Utf8)
          }
          this.$set(this, 'form', form)

        }
      }
      if (this.opertionForm.autoLogin && !this.$route.query.not_auto_login) {
        this.toHome()
      } else {
        window.localStorage.removeItem('opertionForm')
      }
    },
    toLogin () {
     

      this.$http({
        url: '/api/v1/login',
        method: 'POST',
        data: this.form
      }).then(res => {
        window.localStorage.setItem('userInfo', JSON.stringify(res.data))
        // if (res.data.edu_school_id == 1) {
        //   if (this.opertionForm.rememberPassword || this.opertionForm.autoLogin) {
        let form = JSON.parse(JSON.stringify(this.form))
        // 简单加密
        form.password = CryptoJS.AES.encrypt(this.form.password, this.secretkey).toString()
        window.localStorage.setItem('loginForm', JSON.stringify(form))
        // }
        window.localStorage.setItem('opertionForm', JSON.stringify(this.opertionForm))
        // }
        this.$message.success('登录成功')
        this.$router.push('/home')
      })

    },

    async toHome () {
      console.log('sssssssssssssss', this.form.passwordChec)
      // if (this.form.phone == '') {
      //   this.$message.warning('请输入账号');
      //   return
      // }
      // if (this.form.code == '') {
      //   this.$message.warning('请输入短信验证码');
      //   return
      // }
      // if (this.form.password == '') {
      //   this.$message.warning('请输入密码');
      //   return
      // }
      // if (this.form.passwordCheck == '') {
      //   this.$message.warning('请再次确认密码');
      //   return
      // }
      if (!this.opertionForm.consent) {
        this.$message.warning('请同意用户协议');
        return
      }
      // if (this.isShowLogin) {

      if (this.form.truename == '') {
        this.$message.warning('请输入学生姓名');
        return
      }
      if (this.form.edu_grade_id == '') {
        this.$message.warning('请选择年级');
        return
      }
      if (this.form.paper_district_id == '') {
        this.$message.warning('请选择地区');
        return
      }
      this.form.phone = JSON.parse(window.localStorage.getItem('unLogingMeassge')).phone
      this.form.password = JSON.parse(window.localStorage.getItem('unLogingMeassge')).password
    
      this.$http({
        url: '/api/v1/user/register',
        method: 'POST',
        data: this.form
      }).then(res => {
        // window.localStorage.setItem('userInfo', JSON.stringify(res.data))
        // if (res.data.edu_school_id == 1) {
        //   if (this.opertionForm.rememberPassword || this.opertionForm.autoLogin) {
        //     let form = JSON.parse(JSON.stringify(this.form))
        //     // 简单加密
        //     form.password = CryptoJS.AES.encrypt(this.form.password, this.secretkey).toString()
        //     window.localStorage.setItem('loginForm', JSON.stringify(form))
        //   }
        //   window.localStorage.setItem('opertionForm', JSON.stringify(this.opertionForm))
        // }
        this.$message.success('注册成功')
        this.toLogin()
        // this.$router.push('/home')
      })
      //  this.toLogin()
      // } else {
      //   if (this.form.invite_code) {
      //     this.invitecodeVerify()
      //   }

      //   this.$http({
      //     url: '/api/v1/user/verify',
      //     method: 'POST',
      //     data: this.form
      //   }).then(res => {

      //     if (res.data.sms_record_id) {
      //       this.form.sms_record_id = res.data.sms_record_id
      //       if (this.isPass && this.isCheck) {
      //         this.$message.success('验证码验证成功')
      //         this.isShowLogin = true
      //       } else {
      //         this.$message.error('两次密码不一致')
      //       }

      //     } else {
      //       this.$message.warning('验证码验证失败')
      //     }

      //   })

      // }

    },
    invitecodeVerify () {
      this.$http({
        url: '/api/v1/user/check_invite_code',
        method: 'get',
        params: { invite_code: this.form.invite_code }
      }).then(res => {

        if (res.data.has_user == 1) {
          this.$message.success('邀请码验证成功')
        } else {
          this.$message.warning('邀请码验证失败')
        }

      })
    },

    autoLogin () {
      if (this.opertionForm.autoLogin) {
        this.opertionForm.rememberPassword = true
      }
    },
    rememberPassword () {
      if (this.opertionForm.autoLogin && !this.opertionForm.rememberPassword) {
        this.opertionForm.autoLogin = false
      }
    },
    toMiddle () {
      // 利用postMessage，iframe,localStroage传递高中输入的数据到初中页面
      let myIframe = document.getElementById("myIframe");
      if (myIframe) {
        let data = { form: this.form, opertionForm: this.opertionForm }
        myIframe.contentWindow.postMessage(JSON.stringify(data), middleUrl + '/login?fromH=1')
        window.open(middleUrl, "_self")

      } else {
        // window.location.href = middleUrl
        return
      }
    },
    async sendSms () {
      if (!this.form.phone) {
        this.$message.console.error('请输入手机号');
        return
      }
      try {
        console.log('sssssssssss', this.form.phone)
        if (this.form.phone) {
          await getverify({
            phone: this.form.phone,
            type: 'register2'
          })
          Toast({
            message: '发送成功',
            position: 'top',
            className: 'toast_class'
          });
          this.num = 59
          this.intervalId = setInterval(() => {
            this.num -= 1;
            if (this.num == 0) {
              clearInterval(this.intervalId)
            }
          }, 1000);
        } else if (this.form.grade_id == 2) {
          // 初中
          await getverifyM({
            phone: this.form.phone,
            type: register2
          })
          Toast({
            message: '发送成功',
            position: 'top',
            className: 'toast_class'
          });
          this.num = 59
          this.intervalId = setInterval(() => {
            this.num -= 1;
            if (this.num == 0) {
              clearInterval(this.intervalId)
            }
          }, 1000);
        }

      } catch (err) {
        // Toast({
        //   message: err.msg,
        //   position: 'top',
        // });
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    showProtocol () {
      this.dialogVisible = true
    }

  },
  beforeRouteEnter (to, from, next) {
    // 若是从’/index‘，跳转到’/login‘，则不默认直接跳转’/index'
    if (from.path == '/index') {
      next(vm => {
        vm.isAuto = false
      })
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.home_warp {
  width: 100vw;
  // height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/login/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .fixed_wrap {
    position: absolute;
    left: 24rem;
    top: 24rem;

    .fixed_back_btn {
      cursor: pointer;
      top: 15rem;
      left: 25rem;
      width: 50rem;
    }
  }
  .login_warp {
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 256rem;
      height: 176rem;
      top: -152rem;
      left: 50%;
      margin-left: -125rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    flex: 1;
    // margin-top: 25vh;
    // margin-left: 32%;
    // margin-right: 32%;
    // margin-top: 71rem;
    position: relative;
    margin-top: 179rem;
    margin-left: 560rem;
    margin-right: 560rem;
    height: fit-content;
    // padding-bottom: 84rem;
    padding-bottom: 60rem;
    text-align: center;
    // background-image: url("../../assets/login/白板.png");
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    background: #ffffff;
    box-shadow: 0px 2rem 14rem 0px rgba(141, 184, 227, 0.32);
    border-radius: 20rem;
    border: 20rem solid #dcedfc;
    text-align: center;
    .title {
      font-size: 40rem;
      font-weight: 800;
      margin-top: 52rem;
      color: #2196f3;
      text-align: center;
      line-height: 1.6;
    }
    .base_title {
      color: var(--neutral-color-111111, #111);
      margin: 0 128rem;
      font-family: "PingFang SC";
      font-size: 24rem;
      font-style: normal;
      font-weight: 600;
      text-align: left;
      line-height: 36rem; /* 150% */
    }
  }
}
::v-deep .van-button--small {
  height: 56rem;
  padding: 0 8rem;
  font-size: 16rem;
}
::v-deep .el-input--medium .el-input__inner {
  height: 40rem;
  line-height: 40rem;
  font-size: 16rem;
}
.icons {
  width: 20rem;
  height: 20rem;
}
.form {
  width: 520rem;
  margin: 0 128rem;
}
.btns_sms {
  background: transparent;
  border: 0;
  color: #2196f3;
}
.check_warp {
  display: flex;
  margin-top: 24rem;
  div {
    flex: 1;
    color: #666666;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
}
.btn_warp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 28rem;
  flex-direction: column;
  align-items: center;
  .btn {
    width: 360rem;
    height: 48rem;
    line-height: 48rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    font-size: 18rem;
    color: white;
    border-radius: 44rem;
    background: #fb9930;
    font-weight: bold;
    // letter-spacing: 10rem;
    cursor: pointer;
  }
  .btn_text {
    margin-top: 20rem;
    line-height: 24rem;
    cursor: pointer;
    font-size: 18rem;
    color: #fb9930;
    text-decoration: underline;
    &:hover {
      color: #2196f3;
    }
  }
}
::v-deep .el-input__prefix {
  left: 12rem;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 41rem !important;
  font-size: 18rem !important;
}
::v-deep .el-input--medium .el-input__inner {
  height: 56rem !important;
  line-height: 56rem !important;
}
.el-input__prefix {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    padding-left: 1rem;
    width: 24rem;
    height: 24rem;
  }
}
::v-deep .el-input {
  margin-top: 16rem;
  font-size: 18rem;
}
::v-deep .el-select {
  width: 100%;
}

::v-deep .el-input__inner::placeholder {
  font-size: 18rem;
}
::v-deep .el-checkbox__label {
  font-size: 18rem;
  padding-left: 8px;
}
::v-deep .el-checkbox__inner {
  width: 20rem;
  height: 20rem;
  border-radius: 4rem;
}
::v-deep .el-checkbox__input {
  line-height: 1.5;
}

::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 2rem solid #fff;
  border-left: 0;
  border-top: 0;
  width: 5rem;
  height: 11rem;
  left: 6rem;
  position: absolute;
  top: 1rem;
  transform: rotate(38deg) scaleY(0);
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}

::v-deep .el-input__prefix {
  display: flex;
  align-items: center;
}
.level {
  margin-top: 20rem;
  font-size: 18rem;
  width: 360rem;
  margin: 20rem auto 0;
  display: flex;
  justify-content: space-around;
  line-height: 1.5;
  span {
    position: relative;
    padding-left: 25rem;
  }
  span:before {
    width: 18rem;
    height: 18rem;
    box-sizing: border-box;
    content: "";
    // border: 3rem solid #2196f3;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    margin-top: -9rem;
    left: 0;
  }
  span.level_active:before {
    border: 3rem solid #2196f3;
  }
  span.level_normal:before {
    border: 1rem solid #ccc;
  }
}
.protocol {
  color: #3f9eff;
  font-size: 18rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
::v-deep .el-dialog__body {
  padding: 0px 20px 30px;
}
::v-deep .el-dialog__header {
  padding: 0px 20px 10px;
}
::v-deep .el-dialog__headerbtn {
  padding: 2px 3px 1px;
  border-radius: 50%;
  border: 1px solid #bbb;
}
</style>
